import React from 'react';
import portadaNoticias from '../../images/noticias/Noticias_bg.png';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Pulso from '../../components/Pulso';
import QrtNews from '../../components/QrtNews';
import ArticleDashboard from '../../components/ArticleDashboard';

const Noticias = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div
        className="bg-blue-900 text-white p-8 flex flex-col items-center"
        style={{
          backgroundImage: `url(${portadaNoticias})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Header />
        <Pulso />
      </div>

      <div className="flex-grow">
        <QrtNews />
        <ArticleDashboard />
      </div>

      <Footer />
    </div>
  );
};

export default Noticias;
