import ArticleDetail from "../../components/ArticleDetail";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import portadaNoticias from '../../images/noticias/Noticias_bg.png';
import Pulso from "../../components/Pulso";

const DetalleNoticias = () => {
    return (
        <div className='h-fit flex flex-col'>
            <div
                className="bg-blue-900 h-[80vh] text-white p-8 flex flex-col items-center"
                style={{ backgroundImage: `url(${portadaNoticias})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                <Header />
                <Pulso/>
            </div>
            <div className='flex flex-col h-fit'> {/* Cambiado a h-fit para que se ajuste al contenido */}
                <ArticleDetail />
                <Footer />
            </div>
        </div>
    );
}

export default DetalleNoticias;