import React from 'react';
import ContentRenderer from './ContentRenderer';

const ContentEditor = ({ contenidos, onAddContenido, onChange, onFileSelect, onDelete, onSubmit, imagenes,onSave }) => {
  console.log(contenidos,imagenes)
  return (
    <div className="mb-8">
      <div className="mb-4">
        <h2 className="text-lg font-medium mb-2 text-left">Contenidos</h2>
        {contenidos.map((contenido, index) => (
          <ContentRenderer
            key={index}
            contenido={contenido}
            index={index}
            onChange={onChange}
            onFileSelect={onFileSelect}
            onDelete={onDelete}
            imagenes={imagenes}
          />
        ))}
        <button
          type="button"
          className="flex items-center justify-center w-10 h-10 bg-green-500 text-white rounded-full shadow hover:bg-green-600 mt-4"
          onClick={onAddContenido}
        >
          +
        </button>
      </div>
        <div className="flex flex-col gap-1">
      <button
        onClick={onSave}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 shadow"
      >
        Guardar Contenidos
      </button>
      <br/>
      <button
        onClick={onSubmit}
        className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 shadow"
      >
        Guardar y salir
      </button>
      </div>
    </div>
  );
};

export default ContentEditor;
