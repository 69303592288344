import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Home from "../pages/Home";
import React from 'react';
import Servicios from "../pages/Servicios";
import Nosotros from "../pages/Nosotros";
import Noticias from "../pages/Noticias";
import "./styles.css";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import ContactsPage from "../components/ContactsPage";
import ContactDetail from "../components/ContactDetailPage";
import NewsForm from "../components/NewsForm";
import DetalleNoticias from "../pages/DetalleNoticias";
import NewsList from "../components/NewsList";
import EditNews from "../components/EditNews";
import CreatePost from "../components/CreatePost";
import TitleForm from "../components/TitleForm";

function GeneralRoutes({ token }) {
  const location = useLocation();

  const ProtectedRoute = ({ element }) => {
    return token ? element : <Navigate to="/" />;
  };

  return (
    <TransitionGroup>
      <CSSTransition
        // key={location.pathname}
        timeout={400}
        classNames="fade"
      >
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/noticias" element={<Noticias />} />
          <Route path="/login321" element={<Login />} />
          <Route
            path="/article/:id"
            element={<DetalleNoticias />} />
          {
            // Rutas protegidas
          }
          <Route
            path="/dashboard321"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            path="/newsform"
            element={<ProtectedRoute element={<TitleForm />} />}
          />
          <Route
            path="/contacts"
            element={<ProtectedRoute element={<ContactsPage />} />}
          />
          <Route
            path="/contact-details/:id"
            element={<ProtectedRoute element={<ContactDetail />} />}
          />

          <Route
            path="/newslist"
            element={<ProtectedRoute element={<NewsList />} />}
          />
          <Route
            path="/edit-news/:id"
            element={<ProtectedRoute element={<CreatePost />} />}
          />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default GeneralRoutes;
