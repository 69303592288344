import React, { useState, useEffect } from 'react';  
import axios from 'axios';  
import { useParams, useNavigate } from 'react-router-dom';  
import ContentEditor from './ContentEditor';  
import ImageUploader from './ImageUploader';  
import { API_URL } from '../api-config';  
import NewsHeader from './NewsHeader';

const CreatePost = () => {  
  const [contenidos, setContenidos] = useState([]);  
  const [titulo, setTitulo] = useState('');  
  const [autor, setAutor] = useState('');
  const [imagenes,setImagenes] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(false);  
  const { id: noticiaId } = useParams();  
  const [update, setUpdate] = useState(false);
  const navigate = useNavigate();  
  
  const fetchNoticia = async () => {  
    try {  
      const response = await axios.get(`${API_URL}/users-api/news/contenido/${noticiaId}`);  
      const { titulo_principal, autor, contenidos,images } = response.data;  
      setTitulo(titulo_principal);  
      setAutor(autor);  
      setContenidos(contenidos); 
      setImagenes(images); 
      console.log("i",images)
    } catch (error) {  
      console.error('Error al cargar la noticia:', error);  
      alert('No se pudo cargar la noticia.');  
    }  
  };  
  useEffect(() => {  
    const token = localStorage.getItem('authToken');  



    if (noticiaId) {  
      fetchNoticia();  
    }  
  }, [noticiaId]);  
  useEffect(() => {
    if (contenidos.length > 0){
      handleSaveContent();
      fetchNoticia()
    }
    else{
      console.log("no hay contenidos")
    }

        },[update])
  const handleAddContenido = () => {  
    if (contenidos.length === 0 || contenidos[contenidos.length - 1].contenido.trim() !== '') {  
      setContenidos([...contenidos, { tipo: 'texto', orden: contenidos.length + 1, contenido: '' }]);  
    } else {  
      alert('Por favor, completa el contenido anterior antes de agregar uno nuevo.');  
    }  
  };  
  const handleDeleteContenido = (index) => {
    setContenidos((prevContenidos) => prevContenidos.filter((_, idx) => idx !== index));
  };
  const seleccionarPortada = async (noticiaId,portadaId) => {
      try {
        const response = await axios.put(
          `http://${API_URL}/users-api/news/${noticiaId}/portada/${portadaId}`,
          null, // El cuerpo de la solicitud es nulo en este caso
          {
            headers: {
              'accept': 'application/json',
            },
          }
        );
        console.log('Portada seleccionada con éxito:', response.data);
        alert('Portada seleccionada con éxito');
      } catch (error) {
        console.error('Error al seleccionar la portada:', error);
        alert('Hubo un error al seleccionar la portada. Inténtalo nuevamente.');
      }
    };
  
  const handleAddImages = (images) => {  
    const newImageContents = images.map((image) => ({  
      tipo: 'imagen',  
      orden: contenidos.length + 1,  
      contenido: image.url, // URL de la imagen subida
      nombre_unico: image.nombre_unico, // Nombre de la imagen
    }));  

    setContenidos((prev) => [...prev, ...newImageContents]);  
  };  

  const handleSubmitContent = async () => {  
    if (!noticiaId) {  
      alert('Primero cree la noticia y su imagen principal.');  
      return;  
    }  

    try {  
      const token = localStorage.getItem('authToken');  
      await axios.post(  
        `${API_URL}/users-api/news/noticias/contenido/${noticiaId}`,  
        contenidos,  
        {  
          headers: {  
            'Authorization': `Bearer ${token}`,  
            'Content-Type': 'application/json',  
          }  
        }  
      );  

      alert(`Noticia actualizada exitosamente: ID ${noticiaId}`);  
      navigate('/newslist');  
    } catch (error) {  
      console.error('Error al actualizar la noticia:', error);  
      alert('Error al actualizar la noticia.');  
    }  
  };  
  const handleSaveContent = async () => {  
    if (!noticiaId) {  
      alert('Primero cree la noticia y su imagen principal.');  
      return;  
    }  

    try {  
      const token = localStorage.getItem('authToken');  
      await axios.post(  
        `${API_URL}/users-api/news/noticias/contenido/${noticiaId}`,  
        contenidos,  
        {  
          headers: {  
            'Authorization': `Bearer ${token}`,  
            'Content-Type': 'application/json',  
          }  
        }  
      );  
      alert(`Noticia actualizada exitosamente: ID ${noticiaId}`); 
    } catch (error) {  
      console.error('Error al actualizar la noticia:', error);  

    }  
  };  
  return (  
    <div className="container mx-auto p-4 relative">  
      <div className="flex justify-between items-center mb-4">  
        <button  
          className="bg-gray-500 text-white py-1 px-4 rounded"  
          onClick={() => navigate('/newslist')}  
        >  
          Volver Atrás  
        </button>  
        <button  
          className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"  
          onClick={() => setShowImageUploader(!showImageUploader)}  
        >  
          {showImageUploader ? 'Cerrar Imágenes' : 'Agregar Imágenes'}  
        </button>  
      </div>  

      <NewsHeader
        titulo={titulo}
        autor={autor}
        imagenes={imagenes}
        noticiaId={noticiaId}
        update={update}
        setUpdate={setUpdate}
        />


      <ContentEditor  
        contenidos={contenidos}  
        onDelete={handleDeleteContenido}
        onAddContenido={handleAddContenido}  
        onChange={(index, field, value) => {  
          const updatedContenidos = [...contenidos];  
          updatedContenidos[index][field] = value;  
          setContenidos(updatedContenidos);  
        }}  
        onSubmit={handleSubmitContent}  
        onSave={handleSaveContent}
        imagenes = {imagenes}
      />  

      {showImageUploader && (  
        <ImageUploader  
          update={update}
          setUpdate={setUpdate}
          onAddImages={handleAddImages}  
          onClose={() => setShowImageUploader(false)}  
          uploadUrl={`${API_URL}/users-api/news/imagenes`}  
          noticiaId={noticiaId}  
          getImagesUrl={`${API_URL}/users-api/news/imagenes`}  
        />  
      )}  
    </div>  
  );  
};  

export default CreatePost;
