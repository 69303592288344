import React from 'react';
import flowmaster from "../images/gifs/3.gif";
import protech from "../images/gifs/2.gif";
import ai from "../images/gifs/1.gif";
import datavision from "../images/gifs/5.gif";
import cloud from "../images/gifs/4.gif";
import { Helmet } from 'react-helmet';

const Hero = () => {
  return (
    <div className="text-center pt-1 pb-12 flex flex-col items-center sm:items-start w-full md:w-2/3 mt-16 px-4">
      <Helmet>
          <link rel="canonical" href="https://www.qrtsolutions.com" />
      </Helmet>
  <div className='w-full sm:w-3/4 md:w-6/7 flex flex-col items-center md:items-start'>
    <h1 className="text-4xl text-white md:text-6xl font-semibold mb-6 text-center md:text-left">
      La innovación comienza cuando 
      <strong className='font-black'> cuestionamos lo establecido</strong>
    </h1>
    <div className="flex justify-center md:justify-start">
      <button
        className="bg-yellow-500 dark:bg-blue-600 text-blue-900 dark:text-slate-900 rounded-2xl px-8 md:px-12 py-2 h-10 text-lg font-bold"
        onClick={() => window.open('https://qrtwebsite.azurewebsites.net/demo_bi/f0a5bdce-d5c3-4d11-8486-902a491e16b9', '_blank')}
      >
        Ver Demostración
      </button>
    </div>
  </div>
  <div className='grid grid-cols-3 sm:grid-cols-2 gap-4 justify-items-center md:justify-items-start md:flex md:flex-wrap md:justify-between text-white mt-12 md:mt-24 mb-12 w-full'>
  <div className="text-left flex items-center justify-center md:justify-start mb-6 md:mb-0 font-bebas md:flex-1">
    <img src={ai} alt="IA Factory" className="h-12 w-12 mb-2"/>
    <p className='ml-4 sm:font-bold py-4 text-xs sm:text-base'>IA <br />Factory</p>
  </div>
  <div className="text-left flex items-center justify-center md:justify-start mb-6 md:mb-0 font-bebas md:flex-1">
    <img src={protech} alt="Protech Insight" className="h-12 w-12 mb-2"/>
    <p className='ml-4 sm:font-bold py-4 text-xs sm:text-base'>Protech <br /> Insight</p>
  </div>
  <div className="text-left flex items-center justify-center md:justify-start mb-6 md:mb-0 font-bebas md:flex-1">
    <img src={flowmaster} alt="Flowmaster Automation" className="h-12 w-12 mb-2"/>
    <p className='ml-4 sm:font-bold py-4 text-xs sm:text-base'>Flowmaster<br />Automation</p>
  </div>
  {/* Contenedor para los dos últimos logos */}
  <div className="col-span-3 flex justify-center gap-8 sm:hidden w-full">
    <div className="text-left flex items-center justify-center md:justify-start mb-6 md:mb-0 font-bebas md:flex-1">
      <img src={datavision} alt="Datavision BI" className="h-12 w-12 mb-2"/>
      <p className='ml-4 sm:font-bold py-4 text-xs sm:text-base'>Datavision<br /> BI</p>
    </div>
    <div className="text-left flex items-center justify-center md:justify-start mb-6 md:mb-0 font-bebas md:flex-1">
      <img src={cloud} alt="Cloud Nexus" className="h-12 w-12 mb-2"/>
      <p className='ml-4 sm:font-bold py-4 text-xs sm:text-base'>Cloud <br />Nexus</p>
    </div>
  </div>
  
  {/* Versión para tablet/desktop de los últimos dos logos */}
  <div className="hidden sm:flex text-left items-center justify-center md:justify-start mb-6 md:mb-0 font-bebas md:flex-1">
    <img src={datavision} alt="Datavision BI" className="h-12 w-12 mb-2"/>
    <p className='ml-4 sm:font-bold py-4 text-xs sm:text-base'>Datavision<br /> BI</p>
  </div>
  <div className="hidden sm:flex items-center justify-center md:justify-start sm:w-1/2 md:w-auto font-bebas md:flex-1">
    <img src={cloud} alt="Cloud Nexus" className="h-12 w-12 mb-2"/>
    <p className='ml-4 sm:font-bold py-4 text-xs sm:text-base'>Cloud <br />Nexus</p>
  </div>
</div>
</div>
  );
}

export default Hero;
