import React from 'react';
import aiFace from "../images/ai_face.svg";
import modelos from "../images/aboutus/aigif.gif";
import inteligencia from "../images/aboutus/cloudgif.gif";
import analisis from "../images/aboutus/datavisiongif.gif";
import nextPNG from "../images/next_icon.svg";

const AiFactory = () => {
  return (
    <div className="w-screen flex justify-center bg-white dark:bg-[#0c0c0c]">
      <div className="bg-white dark:bg-[#0c0c0c] w-full lg:w-2/3 p-4 md:p-8 rounded-lg text-left">
        <div className="flex flex-col md:flex-row items-center md:items-start text-justify">
          <img 
            src={aiFace} 
            alt="AI Face" 
            className="w-32 h-32 sm:w-48 sm:h-48 md:w-64 md:h-64 object-cover mb-4 md:mb-0 md:mr-8" 
          />
          <div>
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-white dark:text-black px-1 text-center rounded-xl py-1 mb-4 mx-auto sm:mx-0 w-48 sm:w-6/12 md:w-64 bg-[#0052FF]">
              AI Factory
            </h1>
          </div>
          <p className="sm:mt-2 mt-6 text-black dark:text-slate-400 text-xs sm:text-base leading-4 sm:leading-8 text-center sm:text-justify">
  Nuestra IA Factory es un <strong className="font-normal sm:font-bold">centro de excelencia</strong> donde desarrollamos y aplicamos modelos de inteligencia artificial para optimizar procesos y mejorar el rendimiento operativo. Desde la <strong className="font-normal sm:font-bold">automatización avanzada hasta la predicción de demanda y la personalización de servicios</strong>, nuestras soluciones de IA están diseñadas para proporcionar a las empresas una ventaja competitiva significativa. Implementamos modelos de <strong className="font-normal sm:font-bold">machine learning y deep learning</strong> que permiten a las empresas tomar decisiones más informadas, mejorar la eficiencia operativa y reducir costos, todo mientras se adaptan rápidamente a las cambiantes demandas del mercado.
</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 mt-8">
          <div className="bg-zinc-100 dark:bg-black p-4 md:p-6 rounded-lg shadow-md text-justify leading-6">
            <div className="flex items-center mb-4 flex-col">
              <img src={modelos} alt="Modelos AI" className="w-16 h-16 sm:w-24 sm:h-24"/>
              <h2 className="text-lg sm:text-xl font-bold text-blue-600 mt-2">MODELOS AI</h2>
            </div>
            <p className="text-zinc-700 dark:text-slate-400 mb-4">
              Transformamos números crudos en <strong>gráficos intuitivos y paneles de control interactivos</strong>, facilitando la identificación de tendencias y la toma de decisiones basada en datos.
            </p>
            <ul className="list-none text-zinc-700 dark:text-slate-400">
              <li className="flex items-start mb-4">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Integración con múltiples fuentes de datos.
              </li>
              <li className="flex items-start mb-4">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Paneles personalizables según las necesidades de su negocio.
              </li>
              <li className="flex items-start">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Información actualizada en tiempo real para seguimiento continuo.
              </li>
            </ul>
          </div>
          <div className="bg-zinc-100 dark:bg-black p-4 md:p-6 rounded-lg shadow-md text-center leading-6">
            <div className="flex items-center mb-4 flex-col">
              <img src={inteligencia} alt="Inteligencia de Negocios" className="w-16 h-16 sm:w-24 sm:h-24"/>
              <h2 className="text-lg sm:text-xl font-bold text-blue-600 mt-2">INTELIGENCIA DE NEGOCIOS</h2>
            </div>
            <p className="text-zinc-700 dark:text-slate-400 mb-4">
              Utilizamos modelos avanzados de machine learning para <strong>anticipar tendencias, prever demandas y alertar sobre posibles riesgos</strong>, manteniendo su empresa un paso adelante.
            </p>
            <ul className="list-none text-zinc-700 dark:text-slate-400">
              <li className="flex items-start mb-4">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Modelos de predicción personalizados para su industria.
              </li>
              <li className="flex items-start mb-4">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Identificación proactiva de oportunidades y amenazas.
              </li>
              <li className="flex items-start">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Optimización de recursos y maximización de beneficios.
              </li>
            </ul>
          </div>
          <div className="bg-zinc-100 dark:bg-black p-4 md:p-6 rounded-lg shadow-md text-justify leading-6">
            <div className="flex items-center mb-4 flex-col">
              <img src={analisis} alt="Análisis de Datos" className="w-16 h-16 sm:w-24 sm:h-24"/>
              <h2 className="text-lg sm:text-xl font-bold text-blue-600 mt-2">ANÁLISIS DE DATOS</h2>
            </div>
            <p className="text-zinc-700 dark:text-slate-400 mb-4">
              Mejore la eficiencia operativa aplicando nuestros análisis de proceso, lo que resulta en <strong>ciclos de trabajo optimizados y una mayor productividad</strong>.
            </p>
            <ul className="list-none text-zinc-700 dark:text-slate-400">
              <li className="flex items-start mb-4">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Diagnóstico de cuellos de botella y áreas de mejora.
              </li>
              <li className="flex items-start mb-4">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Soluciones basadas en datos para reestructurar flujos de trabajo.
              </li>
              <li className="flex items-start">
                <img src={nextPNG} alt="Next icon" className="w-5 h-5 mr-2"/>
                Implementación de mejores prácticas para un rendimiento sostenible.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AiFactory;
