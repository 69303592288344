import React, { useState } from 'react';
import IMGSearch from '../images/noticias/search.svg';
import { Link } from 'react-router-dom';
import TruncateText from '../utils/TruncateText';

function Sidebar({ featuredArticles }) {
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrar artículos por nombre de noticia
  const filteredArticles = searchTerm
    ? featuredArticles.filter(article =>
        article.titulo_principal.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : featuredArticles.slice(0, 5); // Mostrar solo 5 artículos por defecto

  return (
    <div className="w-80 p-4">
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Buscar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 pr-10 rounded-lg border border-gray-300 text-gray-700 focus:outline-none focus:ring focus:border-blue-300"
        />
        <button className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white rounded-sm p-1 px-3">
          <img src={IMGSearch} alt="Search" className="w-5 h-5" />
        </button>
      </div>
      <h2 className="text-lg font-bold text-white bg-blue-500 px-4 py-2 rounded-t-lg">
        Artículos Destacados
      </h2>
      <div className="bg-gray-100 rounded-b-lg p-4 space-y-4">
        {filteredArticles.length > 0 ? (
          filteredArticles.map((article, index) => (
            <div key={article.id}>
              <div className="flex items-start space-x-4 py-2">
                {article.imagen && (
                  <img
                    src={article.portada}
                    alt="Articulo"
                    className="w-16 h-16 object-cover rounded-md"
                  />
                )}
                <div className="text-left">
                  <h3 className="text-xs font-semibold text-gray-800 leading-tight">
                    {TruncateText(article.titulo_principal || 'Sin contenido', 30)}
                  </h3>
                  <p
                    className="text-gray-500"
                    style={{ fontSize: '0.6rem' }}
                  >
                    Publicado por {article.autor}
                  </p>
                  <Link
                    to={`/article/${article.id}`}
                    className="text-blue-500 font-semibold mt-1"
                    style={{ fontSize: '0.6rem' }}
                  >
                    LEER MÁS{'>'}
                  </Link>
                </div>
              </div>
              {index !== filteredArticles.length - 1 && (
                <div className="border-b border-gray-300 my-2"></div>
              )}
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500">No se encontraron artículos.</p>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
