import React, { useState } from "react";
import axios from "axios";
import logo from '../../images/qrt_logo.png';
import { API_URL } from "../../api-config";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    const data = new URLSearchParams();
    data.append('grant_type', 'password');
    data.append('username', username);
    data.append('password', password);
    data.append('scope', '');
    data.append('client_id', 'string'); // Reemplaza con tu client_id real
    data.append('client_secret', 'string'); // Reemplaza con tu client_secret real

    try {
      const response = await axios.post(`${API_URL}/users-api/auth/token/`, data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.status === 200) {
        const { access_token } = response.data;
        localStorage.setItem('authToken', access_token); // Guarda el token en localStorage
        // Redirige a la página de contactos después de un login exitoso
        window.location.href = '/dashboard321'; // Ajusta la URL de acuerdo con tu estructura
      }
    } catch (error) {
      console.error('Error al hacer login:', error);
      const errorMessage = error.response?.data?.detail || 'Hubo un error en el proceso de login.';
      alert(errorMessage);
    }
  };

  return (
    <div className="grid items-center justify-center min-h-screen bg-blue-500">
      <div className="w-full max-w-sm px-8 pb-8 space-y-4 bg-indigo-700 rounded-3xl">
        {/* Logo */}
        <div className="text-center -mb-7">
          <img src={logo} alt="QRT Logo" />
        </div>

        {/* Formulario */}
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full px-4 py-2 text-black bg-white rounded"
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-3 w-full px-4 py-2 text-black bg-white rounded"
          />
          <div className="text-center">
            <a href="#" className="text-xs text-white">
              ¿Olvidaste tu contraseña?
            </a>
          </div>
          <button
            type="submit"
            className="mt-10 w-2/3 py-2 text-lg font-bold text-white bg-yellow-500 rounded hover:bg-yellow-600"
          >
            Enviar
          </button>
        </form>
      </div>
      {/* Volver al inicio */}
      <div className="text-center mt-4">
        <a href="#" className="text-white underline">
          ← Volver Al Inicio
        </a>
      </div>
    </div>
  );
};

export default Login;
