import logo from './logo.svg';
import './App.css';
import GeneralRoutes from './routes/General';
import Footer from './components/Footer';
import { DarkModeProvider } from './components/DarkModeContext';

function App() {
  const token = localStorage.getItem("authToken");
  return (
    <div className="App">
      <DarkModeProvider>
        <GeneralRoutes token={token}/>
      </DarkModeProvider>
    </div>
  );
}

export default App;
