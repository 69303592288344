import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TimeAgo from "../utils/TimeAgo";
import { API_URL } from "../api-config";
import axios from "axios";

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");


    const fetchNews = async () => {
      try {
        const response = await axios.get(`${API_URL}/users-api/news/`, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setNews(response.data); // Asigna las noticias obtenidas al estado
        } else {
          setError("Error al obtener las noticias");
          console.error("Error al obtener las noticias:", response.statusText);
        }
      } catch (error) {
        setError("Error al obtener las noticias");
        console.error("Error al obtener las noticias:", error.message);
      }
    };

    fetchNews();
  }, [navigate]);

  const handleEditClick = (newsId) => {
    navigate(`/edit-news/${newsId}`);
  };

  const handleDeleteClick = async (newsId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar esta noticia?")) {
      try {
        const token = localStorage.getItem("authToken");
        await axios.delete(`${API_URL}/users-api/news/${newsId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setNews(news.filter((item) => item.id !== newsId));
      } catch (error) {
        setError("Error al eliminar la noticia");
        console.error("Error al eliminar la noticia:", error.message);
      }
    }
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Lista de Noticias</h1>
        <button
          onClick={() => navigate("/newsform")} // Redirigir al formulario de creación de noticias
          className="bg-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-yellow-600"
        >
        
          Crear Noticia
        </button>
      </div>
      {error && <p className="text-red-500 text-center">{error}</p>} {/* Mostrar mensaje de error */}
      <table className="table-auto w-full mt-4 border-collapse">
        <thead>
          <tr className="bg-gray-800 text-white">
            <th className="px-4 py-3 border-b-2 border-gray-200">Título</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Autor</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Fecha de Publicación</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {news.map((item, index) => (
            <tr
              key={item.id}
              className={`cursor-pointer hover:bg-gray-100 ${
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              }`}
            >
              <td className="px-4 py-3 text-gray-700 border-b">{item.titulo_principal}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{item.autor}</td>
              <td className="px-4 py-3 text-gray-700 border-b">
                {item.fecha_publicacion.split("T")[0]}
              </td>
              <td className="px-4 py-3 text-gray-700 border-b">
                <button
                  onClick={() => handleEditClick(item.id)}
                  className="text-blue-500 hover:underline"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleDeleteClick(item.id)}
                  className="text-red-500 hover:underline ml-4"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewsList;
