import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import axios from 'axios';
import { API_URL } from '../api-config';

const ArticleDashboard = () => {
  const [articles, setArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const articlesPerPage = 9;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}/users-api/news/`);
        const data = response.data;
        if (Array.isArray(data)) {
          setArticles(
            data.map((article) => ({
              ...article,
              imagen: article.portada ? `data:image/jpeg;base64,${article.portada}` : null,
            }))
          );
        } else {
          setArticles([]);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const categories = [
    { value: '', label: 'Todo' },
    { value: 'Tecnología', label: 'Tecnología' },
    { value: 'Finanzas', label: 'Finanzas' },
  ];

  const filteredArticles = articles.filter(
    (article) =>
      selectedCategory === '' || article.category === selectedCategory
  );

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
  };

  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);
  const featuredArticles = articles.slice(0, 5);

  return (
    <div className="min-h-screen">
      <div className="container mx-auto p-6">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-3/4 px-4">
            {isLoading ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {Array.from({ length: 9 }).map((_, index) => (
                  <div
                    key={index}
                    className="w-full h-40 bg-gray-300 animate-pulse rounded"
                  ></div>
                ))}
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {currentArticles.map((article) => (
                    <div
                      key={article.id}
                      className="border rounded shadow hover:shadow-md transition p-4 cursor-pointer dark:bg-slate-900"
                      onClick={() => handleArticleClick(article.id)}
                    >
                      {article.imagen && (
                        <img
                          src={article.portada}
                          alt={article.titulo_principal}
                          className="w-full h-40 object-cover rounded mb-4"
                        />
                      )}
                      <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100">
                        {article.titulo_principal}
                      </h3>
                      <p className="text-sm text-gray-500">Por: {article.autor}</p>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center mt-8 space-x-2 text-sm text-gray-600">
                  {Array.from({ length: totalPages }, (_, index) => index + 1).map((number) => (
                    <button
                      key={number}
                      onClick={() => handlePageChange(number)}
                      className={`px-2 py-1 rounded ${
                        number === currentPage ? 'bg-blue-600 text-white' : 'hover:bg-blue-100'
                      }`}
                    >
                      {number}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="w-full lg:w-1/4 px-4">
            <Sidebar featuredArticles={featuredArticles} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDashboard;
