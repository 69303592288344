import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../api-config';

const TitleForm = () => {
  const [titulo, setTitulo] = useState('');
  const [autor, setAutor] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');

  }, [navigate]);

  const handleCreateNoticia = async () => {
    try {
      const payload = {
        titulo_principal: titulo,
        autor,
        publicada: false, // Ajustar si la lógica requiere un valor predeterminado.
      };

      const token = localStorage.getItem('authToken');

      const response = await axios.post(`${API_URL}/users-api/news/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Redirigir a la página de edición con el ID de la noticia creada
      navigate(`/edit-news/${response.data.id}`);
    } catch (error) {
      console.error(error);
      alert('Error al crear la noticia');
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleCreateNoticia();
  };

  return (
    <form onSubmit={handleFormSubmit} className="mb-8 p-8">
      <div className="mb-6 p-8">
        <input
          type="text"
          placeholder="Título Principal"
          className="w-full text-3xl font-bold border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 mb-4 text-left"
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Autor"
          className="w-full text-lg text-gray-500 border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 text-left"
          value={autor}
          onChange={(e) => setAutor(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        className="w-64 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 shadow"
      >
        Continuar
      </button>
    </form>
  );
};

export default TitleForm;
