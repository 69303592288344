import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../api-config';

const NewsHeader = ({ titulo, autor, imagenes, noticiaId, update, setUpdate }) => {
  const [mostrarSelector, setMostrarSelector] = useState(false);

  const seleccionarPortada = async (noticiaId, portadaId) => {
    try {
      console.log("noticiaId", noticiaId, "portadaId", portadaId);
      const response = await axios.put(
        `${API_URL}/users-api/news/${noticiaId}/portada/${portadaId}`,
        null,
        {
          headers: {
            accept: 'application/json',
          },
        }
      );
      console.log('Portada seleccionada con éxito:', response.data);
      alert('Portada seleccionada con éxito');
      window.location.reload() // Refrescar estado en lugar de recargar página
    } catch (error) {
      console.error('Error al seleccionar la portada:', error);
      alert('Hubo un error al seleccionar la portada. Inténtalo nuevamente.');
    }
  };

  return (
    <div className="mb-6">
      {imagenes.length > 0 ? (
        <>
          {/* Mostrar imagen de portada si existe */}
          {imagenes.some((img) => img.is_portada) ? (
            <img
              src={imagenes.find((img) => img.is_portada).url}
              alt={`Portada de ${titulo}`}
              className="w-full h-fit object-cover rounded-lg mb-4"
            />
          ) : (
            <p className="text-red-500 mb-2">
              No hay una imagen marcada como portada. Selecciona una:
            </p>
          )}

          {/* Botón para alternar el selector */}
          <button
            onClick={() => setMostrarSelector(!mostrarSelector)}
            className="text-sm text-blue-500 hover:underline"
          >
            {mostrarSelector ? 'Cerrar selector' : 'Seleccionar una portada'}
          </button>

          {/* Mostrar todas las imágenes como opciones */}
          {mostrarSelector && (
            <div className="grid grid-cols-3 gap-4 mt-4">
              {imagenes.map((img, index) => (
                <button
                  key={index}
                  onClick={() => seleccionarPortada(noticiaId, img.id)}
                  className="relative"
                >
                  <img
                    src={img.url}
                    alt={`Imagen ${index + 1}`}
                    className={`w-full h-32 object-cover rounded-lg ${
                      img.is_portada ? 'border-4 border-blue-500' : ''
                    }`}
                  />
                  {!img.is_portada && (
                    <span className="absolute bottom-1 left-1 text-white bg-black/70 text-xs px-2 py-1 rounded">
                      Seleccionar
                    </span>
                  )}
                </button>
              ))}
            </div>
          )}
        </>
      ) : (
        <p className="text-red-500">No hay imágenes disponibles. Por favor, sube una imagen.</p>
      )}

      {/* Título y autor */}
      <h1 className="text-3xl font-bold">{titulo}</h1>
      <p className="text-lg text-gray-500">Autor: {autor}</p>
    </div>
  );
};

export default NewsHeader;
