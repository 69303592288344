import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URL } from "../api-config";

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");



    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${API_URL}/users-api/clients/contacts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setContacts(response.data);
      } catch (error) {
        console.error("Error al obtener los contactos:", error);
        alert("Hubo un error al cargar la lista de contactos.");
      }
    };

    fetchContacts();
  }, [navigate]);

  const handleRowClick = (contactId) => {
    navigate(`/contact-details/${contactId}`);
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold text-center text-gray-800">Lista de Contactos</h1>
      <table className="table-auto w-full mt-6 border-collapse">
        <thead>
          <tr className="bg-gray-800 text-white">
            <th className="px-4 py-3 border-b-2 border-gray-200">Nombre</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Correo</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Empresa</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Teléfono</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Tipo de Consulta</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Mensaje</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact, index) => (
            <tr
              key={contact.id}
              onClick={() => handleRowClick(contact.id)}
              className={`cursor-pointer hover:bg-gray-100 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
            >
              <td className="px-4 py-3 text-gray-700 border-b">{contact.name || "Sin nombre"}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.email || "Sin correo"}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.company || "Sin empresa"}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.phone || "Sin teléfono"}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.query_type || "Sin tipo"}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.message || "Sin mensaje"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactList;
