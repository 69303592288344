import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users, Newspaper } from 'lucide-react';

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');

  }, [navigate]);

  const cards = [
    {
      title: 'Contactos',
      icon: <Users size={48} />,
      path: '/contacts',
      description: 'Gestionar contactos y información'
    },
    {
      title: 'Noticias',
      icon: <Newspaper size={48} />,
      path: '/newslist',
      description: 'Ver y administrar noticias'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-blue-700 p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-white mb-8 text-center">Panel de Control</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-items-center">
          {cards.map((card) => (
            <div
              key={card.path}
              onClick={() => navigate(card.path)}
              className="w-full max-w-sm p-6 rounded-xl bg-white/10 backdrop-blur-lg 
                        border border-white/20 cursor-pointer
                        transform transition-all duration-300
                        hover:scale-105 hover:bg-white/20
                        group"
            >
              <div className="flex flex-col items-center space-y-4">
                <div className="text-white group-hover:scale-110 transition-transform">
                  {card.icon}
                </div>
                <h2 className="text-2xl font-semibold text-white">{card.title}</h2>
                <p className="text-white/80 text-center">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;