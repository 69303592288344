import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import emailIcon from '../images/email.svg';
import spanishIcon from '../images/espanol.svg'; // Asegúrate de que la ruta sea correcta
import qrtLogo from "../images/qrt_logo.png";
import wasap from "../images/wasap.svg";
import { DarkModeContext } from './DarkModeContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className='bg-transparent w-full md:w-2/3 text-white flex flex-col py-2'>
      <div className='flex justify-between items-center md:border-white md:border-b md:pb-2 md:px-4'>
        <div className='flex items-center'>
        <div className='md:hidden p-0'>
        <img src={qrtLogo} alt="QRT Logo" className="h-32 pl-4 w-full object-contain" />
        </div>
        <div className='hidden md:flex items-center'>
          <img src={emailIcon} alt="Email Icon" className="h-6 w-6 mr-2" />
          <h2 className='text-[12px] font-medium leading-[15.6px] text-left'>
            contacto@qrtsolutions.com
          </h2>
        </div>
        </div>
        <div className="relative flex items-center">
          {/* <img src={spanishIcon} alt="Español Icon" className="h-4 w-4 mr-2" /> */}
          <select className='bg-transparent text-sm appearance-none pr-6'>
            {/* <option value="es" selected>Español</option> */}
            {/* <option value="en">English</option> */}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
          </div>
          <button 
            className='hidden md:block bg-transparent border-2 text-sm font-bold py-1 px-8 rounded-lg ml-4'
            onClick={() => window.open('https://qrtwebsite.azurewebsites.net/', '_blank')}
          >
            Portal Clientes
          </button>
        </div>
        <label className="md:hidden inline-flex items-center relative mr-14"> {/* Agregado hidden md:flex */}
          <input className="peer hidden" id="toggle" type="checkbox" checked={isDarkMode} onChange={toggleDarkMode} />
          <div className="relative w-[80px] h-[35px] bg-white peer-checked:bg-zinc-500 rounded-full after:absolute after:content-[''] after:w-[25px] after:h-[25px] after:bg-gradient-to-r from-orange-500 to-yellow-400 peer-checked:after:from-zinc-900 peer-checked:after:to-zinc-900 after:rounded-full after:top-[5px] after:left-[5px] active:after:w-[30px] peer-checked:after:left-[75px] peer-checked:after:translate-x-[-100%] shadow-sm duration-300 after:duration-300 after:shadow-md"></div>
          <svg
          height="0"
          width="70" // Cambiado de 80 a 70
          viewBox="0 0 24 24"
          data-name="Layer 1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          class="fill-white peer-checked:opacity-60 absolute w-4 h-4 left-[8px]" // Cambiado de w-5 h-5 a w-4 h-4
        >
          <path
            d="M12,17c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5ZM13,0h-2V5h2V0Zm0,19h-2v5h2v-5ZM5,11H0v2H5v-2Zm19,0h-5v2h5v-2Zm-2.81-6.78l-1.41-1.41-3.54,3.54,1.41,1.41,3.54-3.54ZM7.76,17.66l-1.41-1.41-3.54,3.54,1.41,1.41,3.54-3.54Zm0-11.31l-3.54-3.54-1.41,1.41,3.54,3.54,1.41-1.41Zm13.44,13.44l-3.54-3.54-1.41,1.41,3.54,3.54,1.41-1.41Z"
          ></path>
          </svg>
          <svg
            height="512"
            width="512"
            viewBox="0 0 24 24"
            data-name="Layer 1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            class="fill-black opacity-60 peer-checked:opacity-70 peer-checked:fill-white absolute w-4 h-4 right-[8px]" // Cambiado de w-5 h-5 a w-4 h-4
          >
            <path
              d="M12.009,24A12.067,12.067,0,0,1,.075,10.725,12.121,12.121,0,0,1,10.1.152a13,13,0,0,1,5.03.206,2.5,2.5,0,0,1,1.8,1.8,2.47,2.47,0,0,1-.7,2.425c-4.559,4.168-4.165,10.645.807,14.412h0a2.5,2.5,0,0,1-.7,4.319A13.875,13.875,0,0,1,12.009,24Zm.074-22a10.776,10.776,0,0,0-1.675.127,10.1,10.1,0,0,0-8.344,8.8A9.928,9.928,0,0,0,4.581,18.7a10.473,10.473,0,0,0,11.093,2.734.5.5,0,0,0,.138-.856h0C9.883,16.1,9.417,8.087,14.865,3.124a.459.459,0,0,0,.127-.465.491.491,0,0,0-.356-.362A10.68,10.68,0,0,0,12.083,2ZM20.5,12a1,1,0,0,1-.97-.757l-.358-1.43L17.74,9.428a1,1,0,0,1,.035-1.94l1.4-.325.351-1.406a1,1,0,0,1,1.94,0l.355,1.418,1.418.355a1,1,0,0,1,0,1.94l-1.418.355-.355,1.418A1,1,0,0,1,20.5,12ZM16,14a1,1,0,0,0,2,0A1,1,0,0,0,16,14Zm6,4a1,1,0,0,0,2,0A1,1,0,0,0,22,18Z"
            ></path>
          </svg>
        </label>
        <button 
          className='block md:hidden text-2xl mr-10 border-4 px-3 p-2 rounded-xl'
          onClick={toggleDropdown}
        >
          &#9776; {/* Ícono de hamburguesa */}
        </button>
      </div>
      <div className='flex-col md:flex-row justify-between items-center px-4 md:px-0 mt-2 md:flex'>
        <div className='hidden md:block md:h-32 md:w-64  md:-ml-16 lg:m-0'>
        <img src={qrtLogo} alt="QRT Logo" className="h-16 md:h-32 md:-ml-2 md:-ml-12 -mt-2 md:-mt-6 " />
        </div>
        
        
        <div className={`flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6 -mt-4 md:-mt-8 ${isOpen ? 'block' : 'hidden md:flex'}`}>
        {/* <DarkModeToggle /> */}
        <label className="items-center relative hidden md:flex"> {/* Agregado hidden md:flex */}
          <input className="peer hidden" id="toggle" type="checkbox" checked={isDarkMode} onChange={toggleDarkMode} />
          <div className="relative w-[80px] h-[35px] bg-white peer-checked:bg-zinc-500 rounded-full after:absolute after:content-[''] after:w-[25px] after:h-[25px] after:bg-gradient-to-r from-orange-500 to-yellow-400 peer-checked:after:from-zinc-900 peer-checked:after:to-zinc-900 after:rounded-full after:top-[5px] after:left-[5px] active:after:w-[30px] peer-checked:after:left-[75px] peer-checked:after:translate-x-[-100%] shadow-sm duration-300 after:duration-300 after:shadow-md"></div>
          <svg
          height="0"
          width="70" // Cambiado de 80 a 70
          viewBox="0 0 24 24"
          data-name="Layer 1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          class="fill-white peer-checked:opacity-60 absolute w-4 h-4 left-[8px]" // Cambiado de w-5 h-5 a w-4 h-4
        >
          <path
            d="M12,17c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5ZM13,0h-2V5h2V0Zm0,19h-2v5h2v-5ZM5,11H0v2H5v-2Zm19,0h-5v2h5v-2Zm-2.81-6.78l-1.41-1.41-3.54,3.54,1.41,1.41,3.54-3.54ZM7.76,17.66l-1.41-1.41-3.54,3.54,1.41,1.41,3.54-3.54Zm0-11.31l-3.54-3.54-1.41,1.41,3.54,3.54,1.41-1.41Zm13.44,13.44l-3.54-3.54-1.41,1.41,3.54,3.54,1.41-1.41Z"
          ></path>
          </svg>
          <svg
            height="512"
            width="512"
            viewBox="0 0 24 24"
            data-name="Layer 1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            class="fill-black opacity-60 peer-checked:opacity-70 peer-checked:fill-white absolute w-4 h-4 right-[8px]" // Cambiado de w-5 h-5 a w-4 h-4
          >
            <path
              d="M12.009,24A12.067,12.067,0,0,1,.075,10.725,12.121,12.121,0,0,1,10.1.152a13,13,0,0,1,5.03.206,2.5,2.5,0,0,1,1.8,1.8,2.47,2.47,0,0,1-.7,2.425c-4.559,4.168-4.165,10.645.807,14.412h0a2.5,2.5,0,0,1-.7,4.319A13.875,13.875,0,0,1,12.009,24Zm.074-22a10.776,10.776,0,0,0-1.675.127,10.1,10.1,0,0,0-8.344,8.8A9.928,9.928,0,0,0,4.581,18.7a10.473,10.473,0,0,0,11.093,2.734.5.5,0,0,0,.138-.856h0C9.883,16.1,9.417,8.087,14.865,3.124a.459.459,0,0,0,.127-.465.491.491,0,0,0-.356-.362A10.68,10.68,0,0,0,12.083,2ZM20.5,12a1,1,0,0,1-.97-.757l-.358-1.43L17.74,9.428a1,1,0,0,1,.035-1.94l1.4-.325.351-1.406a1,1,0,0,1,1.94,0l.355,1.418,1.418.355a1,1,0,0,1,0,1.94l-1.418.355-.355,1.418A1,1,0,0,1,20.5,12ZM16,14a1,1,0,0,0,2,0A1,1,0,0,0,16,14Zm6,4a1,1,0,0,0,2,0A1,1,0,0,0,22,18Z"
            ></path>
          </svg>
        </label>
          <Link to="/" className='hover:text-zinc-300 font-bold'>Inicio</Link>
          <Link to="/servicios" className='hover:text-zinc-300 font-bold'>Servicios</Link>
          <Link to="/nosotros" className='hover:text-zinc-300 font-bold'>Nosotros</Link>
          <Link to="/noticias" className='hover:text-zinc-300 font-bold'>Noticias</Link>
          {/* <a href="#" className="hover:text-zinc-300 font-bold">Contacto</a> */}
          <button className='bg-transparent flex items-center justify-around w-36 border-yellow-500 border text-yellow-500 px-4 py-1 rounded-3xl hover:bg-yellow-600'
            onClick={() => window.open('https://wa.me/message/2KDGNSCDPMTRC1', '_blank')}
          >
            <img src={wasap} alt="WhatsApp Icon" className="h-6 w-4"/>
            Hablemos
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
