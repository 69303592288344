import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ImageUploader = ({ onAddImages, onClose, uploadUrl, noticiaId,update,setUpdate }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageName, setImageName] = useState('');
  const [uploading, setUploading] = useState(false);
  const [imagenes, setImagenes] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);

  const validExtensions = ['jpeg', 'jpg', 'png', 'webp'];

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${uploadUrl}/${noticiaId}`);
      setImagenes(response.data);
    } catch (error) {
      console.error('Error al cargar imágenes:', error.response?.data || error);
      alert('Error al cargar las imágenes existentes.');
    } finally {
      setLoadingImages(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      if (!validExtensions.includes(extension)) {
        alert('Formato de archivo no permitido. Solo se aceptan JPEG, PNG y WEBP.');
        return;
      }
      setSelectedImage(file);

      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      alert('Por favor, selecciona una imagen antes de subir.');
      return;
    }
    if (!imageName.trim()) {
      alert('Por favor, asigna un nombre a la imagen.');
      return;
    }
  
    const token = localStorage.getItem('authToken');
    const formData = new FormData();
    formData.append('id_noticia', noticiaId);
    formData.append('nombre_unico', imageName.trim());
    formData.append('is_portada', false);
    formData.append('file', selectedImage);
  
    setUploading(true);
  
    try {
      await axios.post(`${uploadUrl}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert(`Imagen "${selectedImage.name}" subida correctamente con el nombre "${imageName}".`);
      setSelectedImage(null);
      setPreviewUrl(null);
      setImageName('');
      fetchImages();
      setUpdate(!update)
      window.location.reload()
    } catch (error) {
      console.error(`Error al subir la imagen "${selectedImage.name}":`, error.response?.data || error);
      alert('Error al subir la imagen. Intente nuevamente.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div
      className="fixed top-0 right-0 w-1/3 bg-white shadow-lg h-full p-4 border-8 overflow-y-auto"
      style={{ maxHeight: '100vh' }} // Asegura que el contenedor tenga una altura máxima del 100% de la ventana
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Agregar Imágenes</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 font-bold text-lg"
        >
          ×
        </button>
      </div>

      <div className="mb-4">
        <h3 className="font-medium mb-2">Imágenes Existentes:</h3>
        {loadingImages ? (
          <p className="text-gray-500 text-sm">Cargando imágenes...</p>
        ) : imagenes.length > 0 ? (
          <div className="flex flex-wrap gap-4">
            {imagenes.map((img) => (
              <div key={img.id} className="w-24 h-32 flex flex-col items-center">
                <div className="w-full h-24 relative">
                  <img
                    src={img.url}
                    alt={img.nombre_unico}
                    className="w-full h-full object-cover rounded shadow"
                  />
                  {img.is_portada && (
                    <span className="absolute top-0 left-0 bg-green-500 text-white text-xs px-1 rounded">
                      Portada
                    </span>
                  )}
                </div>
                <p className="text-sm text-center mt-1 text-gray-700 break-words">{img.nombre_unico}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-sm">No hay imágenes cargadas.</p>
        )}
      </div>

      <input
        type="file"
        accept={validExtensions.map((ext) => `image/${ext}`).join(',')}
        onChange={handleImageChange}
        className="mb-4 w-full border border-gray-300 p-2 rounded"
        disabled={uploading}
      />
      {selectedImage && (
        <div className="mb-4">
          <h3 className="font-medium mb-2">Vista Previa:</h3>
          <img
            src={previewUrl}
            alt="Vista previa"
            className="w-full h-auto object-contain rounded shadow mb-2"
          />
          <input
            type="text"
            placeholder="Asigna un nombre a la imagen"
            value={imageName}
            onChange={(e) => setImageName(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            disabled={uploading}
          />
        </div>
      )}
      <button
        className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${
          uploading ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={handleUpload}
        disabled={uploading || !selectedImage || !imageName.trim()}
      >
        {uploading ? 'Subiendo...' : 'Subir Imagen'}
      </button>
      <button
        className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mt-4"
        onClick={onClose}
        disabled={uploading}
      >
        Cerrar
      </button>
    </div>
  );
};

export default ImageUploader;
