import React from 'react'
import { Link } from 'react-router-dom'
import rocketImg from '../images/rocket.svg'
import rocketIconImg from '../images/rocket_icon.svg'
const Services = () => {
  return (
    
<div className='h-auto'> 
      <div className='bg-[#F0F0F0] dark:bg-[#070707] flex  flex-col h-auto w-screen justify-center items-center rounded-b-[50px] md:rounded-b-[200px]'>
      <div className='bg-white dark:bg-[#232323] rounded-xl flex md:flex -mt-8 z-10 w-[90%] md:w-2/3 shadow-xl'>
              
      <div className='bg-white dark:bg-[#232323] items-center md:items-left flex flex-col rounded-xl p-8'> 
        <h2 className="md:text-2xl text-lg font-bold text-black text-center md:text-left dark:text-white">
        Lleva tu negocio al siguiente nivel con soluciones personalizadas
        </h2>
        <p className="text-zinc-700 mt-2 dark:text-[#b9b9b9] text-xs md:text-base text-center sm:text-justify">
        Nuestro enfoque integral asegura que cada solución esté diseñada a medida para tus necesidades específicas, desde la evaluación inicial hasta la implementación y seguimiento. Alineamos cada etapa del proceso con tus objetivos estratégicos para maximizar el impacto en tu negocio.
        </p>              
        {/* Botón para mobile */}
        <div className="md:hidden mt-6">
         <button 
           className="bg-yellow-500 dark:bg-blue-600 text-black font-semibold py-2 px-8 rounded-full"
           onClick={() => window.open('https://wa.me/message/2KDGNSCDPMTRC1', '_blank')}
         >
           Contáctanos
         </button>
       </div>
      </div>
      {/* Botón para desktop */}
      <div className="hidden md:flex items-center border-l border-zinc-300 h-full mx-6 py-8 px-4 self-center">
        <button className="bg-yellow-500 dark:bg-blue-600 text-black dark:text-slate-900 font-semibold py-2 px-4 rounded-full" onClick={() => window.open('https://wa.me/message/2KDGNSCDPMTRC1', '_blank')}>
          Contáctanos
        </button>
      </div>
    </div>
          <div className="flex flex-col md:flex-row items-center text-justify w-[95%] md:w-2/3 mt-12 ">
          <div className="bg-trasparent px-4 md:pr-12 w-full md:w-auto text-center md:text-left">
  <p className="text-blue-600 text-sm font-semibold">01 | SERVICIOS</p>
  <h2 className="text-xl dark:text-white md:text-4xl font-semibold text-zinc-900 mt-2">
    Transformación digital y el 
    <strong> poder de la innovación</strong>
  </h2>
  <p className="text-black dark:text-white leading-5 md:leading-8 mt-4 text-xs md:text-base">
    En <span className="font-bold">QRT Solutions</span>, desbloqueamos el poder de tus datos. Con un enfoque pionero en <span className="font-bold">análisis de datos</span> e <span className="font-bold">inteligencia artificial</span>, convertimos complejidades en claridad, impulsando a las empresas hacia la <span className="font-bold">eficiencia</span>, la <span className="font-bold">innovación</span> y el éxito sin precedentes. En <span className="font-bold">QRT Solutions</span> te guiaremos en cada paso, asegurando que cada decisión te acerque más a tus objetivos.
  </p>
  <div className="flex justify-center md:justify-start">
    <Link to="/servicios">
      <button className="mt-2 border-blue-500 bg-trasparent border-2 text-blue-500 py-1 px-4 md:px-8 md:rounded-lg rounded-3xl hover:bg-blue-600 hover:text-white text-xs md:text-base">
        Más Información
      </button>
    </Link>
  </div>
</div>
          <img src={rocketImg} alt="Rocket" className="w-3/4 md:w-1/3  md:mt-0 "/>
        </div>
      </div>


      <div class="bg-white dark:bg-[#0c0c0c] py-8 mt-12 mb-12">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
            <div class="text-center">
              <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4 w-12 md:w-auto"/>
              <h3 class="text-sm md:text-xl font-bold text-blue-600 font-bebas">MODELOS AI</h3>
              <p class="text-zinc-600 dark:text-[#c2c2c2] text-xs md:text-base">Incluimos modelos de IA que se adaptan mejor a tu industria, para llevar tu negocio al siguiente nivel.</p>
            </div>
            
            <div class="text-center">
              <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4 w-12 md:w-auto"/>
              <h3 class="text-sm md:text-xl font-bold text-blue-600 font-bebas">INTELIGENCIA DE NEGOCIOS</h3>
              <p class="text-zinc-600 dark:text-[#c2c2c2] text-xs md:text-base">Transformamos grandes volúmenes de datos en información valiosa para la toma de decisiones estratégicas.</p>
            </div>
            
            <div class="text-center">
              <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4 w-12 md:w-auto"/>
              <h3 class="text-sm md:text-xl font-bold text-blue-600 font-bebas">ANÁLISIS AVANZADOS</h3>
              <p class="text-zinc-600 dark:text-[#c2c2c2] text-xs md:text-base">Aplicamos técnicas de análisis avanzadas para desglosar complejidades y revelar oportunidades ocultas.</p>
            </div>
            
            <div class="text-center">
              <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4 w-12 md:w-auto"/>
              <h3 class="text-sm md:text-xl font-bold text-blue-600 font-bebas">AUTOMATIZACIÓN Y EFICIENCIA</h3>
              <p class="text-zinc-600 dark:text-[#c2c2c2] text-xs md:text-base">Automatizamos procesos para incrementar la eficiencia, reducir errores y maximizar el retorno de inversión.</p>
            </div>
            
            <div class="text-center">
              <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4 w-12 md:w-auto"/>
              <h3 class="text-sm md:text-xl font-bold text-blue-600 font-bebas">SOLUCIONES PERSONALIZADAS</h3>
              <p class="text-zinc-600 dark:text-[#c2c2c2] text-xs md:text-base">Diseñamos soluciones a medida que se adaptan perfectamente a las necesidades específicas de su empresa.</p>
            </div>
            
            <div class="text-center">
              <img src={rocketIconImg} alt="rocket icon" class="mx-auto mb-4 w-12 md:w-auto"/>
              <h3 class="text-sm md:text-xl font-bold text-blue-600 font-bebas">CARACTERÍSTICAS INMEDIATAS</h3>
              <p class="text-zinc-600 dark:text-[#c2c2c2] text-xs md:text-base">Ofrecemos soluciones en tiempo real para optimizar la eficiencia operativa y la comprensión de los datos.</p>
            </div>
          </div>
        </div>
      </div>
</div>
  )
}

export default Services
