import React, { useState } from "react";
import iconWhatsapp from '../images/whatsapp.svg';
import iconLinkedin from '../images/linkedin.svg';
import iconInstagram from '../images/instagram.svg';
import { API_URL } from "../api-config";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    query_type: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const response = await fetch(`${API_URL}/users-api/clients/contact`, {

        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert("Mensaje enviado con éxito.");
      } else {
        alert("Error al enviar el mensaje.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error de red.");
    }
  };

  return (
    <div className="bg-blue-50 dark:bg-[#0b1624] min-h-screen flex items-center justify-center p-6">
      <div className="w-full max-w-5xl">
        {/* Section Header */}
        <div className="flex flex-col md:flex-row items-center md:items-start">
  <div className="text-center md:text-start">
    <h2 className="text-blue-500 dark:text-yellow-500 text-xs md:text-sm font-bold">04 | CONTACTO</h2>
    <h1 className="mr-0 md:mr-6 text-lg md:text-4xl font-bold text-gray-900 dark:text-white">HABLEMOS</h1>
    
    {/* Divider Line - Mobile */}
    <div className="w-24 h-0.5 border-t-2 border-blue-500 dark:border-yellow-500 my-4 mx-auto md:hidden"></div>
  </div>

  {/* Divider Line - Desktop */}
  <div className="hidden md:block h-10 border-l-2 md:mt-3 border-blue-500 dark:border-yellow-500"></div>

  {/* Social Icons */}
  <div className="flex items-center md:mt-0 md:ml-7">  
    <a href="https://www.instagram.com/qrt_solutions?igsh=ems5MXlqa2Q3Y25w" target="_blank" rel="noopener noreferrer">  
      <div className="bg-yellow-500 rounded-full p-2 mr-4"> {/* Fondo redondo */}
        <img src={iconInstagram} alt="Instagram Icon" className="h-6 w-6" />  
      </div>  
    </a>  
    <a href="https://www.linkedin.com/company/qrtsolutions/" target="_blank" rel="noopener noreferrer">  
      <div className="bg-yellow-500 rounded-full p-1 mr-4">
        <img src={iconLinkedin} alt="LinkedIn Icon" className="h-9 w-9 p-2" />  
      </div>
    </a>  
    <a href="https://wa.me/message/2KDGNSCDPMTRC1" target="_blank" rel="noopener noreferrer"> 
      <div className="bg-yellow-500 rounded-full p-1"> 
        <img src={iconWhatsapp} alt="WhatsApp Icon" className="h-10 w-10 p-1" />  
      </div>
    </a>  
  </div>
</div>

        {/* Subtitle */}
        <p className="text-gray-700 dark:text-slate-400 my-8 text-center md:text-start text-xs md:text-base">
          ¿Te interesa saber más? No dudes en dejarnos un mensaje con tus dudas o comentarios.
          Estaremos encantados de ponernos en contacto contigo lo antes posible para brindarte toda la
          información que necesites.
        </p>

        {/* Contact Form */}
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              onChange={handleChange}
              value={formData.name}
              className="border border-gray-300 dark:border-none dark:bg-[#0c0c0c] p-3 rounded-md w-full"
            />
            <input
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              onChange={handleChange}
              value={formData.email}
              className="border border-gray-300 dark:border-none dark:bg-[#0c0c0c] p-3 rounded-md w-full"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <input
              type="text"
              name="phone"
              placeholder="Número de Contacto"
              onChange={handleChange}
              value={formData.phone}
              className="border border-gray-300 dark:border-none dark:bg-[#0c0c0c] p-3 rounded-md w-full"
            />
            <input
              type="text"
              name="company"
              placeholder="Empresa"
              onChange={handleChange}
              value={formData.company}
              className="border border-gray-300 dark:border-none dark:bg-[#0c0c0c] p-3 rounded-md w-full"
            />
            <select
              name="query_type"
              onChange={handleChange}
              value={formData.query_type}
              className="border border-gray-300 dark:border-none dark:bg-[#0c0c0c] p-3 rounded-md w-full"
            >
              <option value="">Tipo de Consulta</option>
              <option value="consulta">Consulta</option>
              <option value="soporte">Soporte</option>
              {/* Agrega más opciones aquí si es necesario */}
            </select>
          </div>

          <textarea
            name="message"
            placeholder="Mensaje"
            onChange={handleChange}
            value={formData.message}
            className="border border-gray-300 dark:border-none dark:bg-[#0c0c0c] p-3 rounded-md w-full h-32"
          ></textarea>

          {/* Submit Button */}
          <div className="flex justify-center sm:justify-end">
            <button
              type="submit"
              className="bg-yellow-500 dark:text-black text-sm hover:bg-yellow-600 text-white font-bold py-2 px-24 rounded-md"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;