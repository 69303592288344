import React, { useState } from 'react';
import { FiEdit, FiCheck, FiTrash } from 'react-icons/fi';

const ContentRenderer = ({ contenido, index, onChange, onFileSelect, onDelete, imagenes }) => {
  const [modo, setModo] = useState('vista');
  console.log(imagenes,"imagenes")
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      onFileSelect(index, e.target.files[0]);
    }
  };

  const handleLinkChange = (field, value) => {
    if (field === 'nombre') {
      onChange(index, 'contenido', value);
    } else if (field === 'url') {
      onChange(index, 'contenido_binario', value);
    }
  };

  const handleImageSelect = (selectedId) => {
    const selectedImage = imagenes.find((img) => img.id === parseInt(selectedId, 10));
    if (selectedImage) {
      onChange(index, 'contenido', selectedImage.url);
    }
  };

  if (modo === 'vista') {
    return (
      <div className="relative mb-4">
        <div className="absolute top-2 right-2 flex gap-2">
          <div
            className="text-gray-400 hover:text-gray-600 cursor-pointer"
            onClick={() => setModo('editar')}
          >
            <FiEdit />
          </div>
          <div
            className="text-red-500 hover:text-red-700 cursor-pointer"
            onClick={() => onDelete(index)}
          >
            <FiTrash />
          </div>
        </div>
        {contenido.tipo === 'h1' && <h1 className="text-2xl font-bold text-left">{contenido.contenido}</h1>}
        {contenido.tipo === 'h2' && <h2 className="text-xl font-semibold text-left">{contenido.contenido}</h2>}
        {contenido.tipo === 'texto' && <p className="text-base leading-relaxed text-left">{contenido.contenido}</p>}
        {contenido.tipo === 'cita' && (
          <blockquote className="italic text-gray-700 border-l-4 pl-4 text-left">{contenido.contenido}</blockquote>
        )}
        {contenido.tipo === 'lista' && (
          <ul className="list-disc pl-6 text-left">
            {contenido.contenido.split(',').map((item, idx) => (
              <li key={idx}>{item.trim()}</li>
            ))}
          </ul>
        )}
        {contenido.tipo === 'codigo' && (
          <pre className="bg-gray-100 p-4 rounded shadow text-sm text-left">
            <code>{contenido.contenido}</code>
          </pre>
        )}
        {contenido.tipo === 'imagen' && (
          <img
            src={contenido.contenido}
            alt="Vista previa de la imagen"
            className="max-w-full rounded shadow"
          />
        )}
        {contenido.tipo === 'enlace' && contenido.contenido && contenido.contenido_binario && (
          <a
            href={contenido.contenido_binario}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            {contenido.contenido}
          </a>
        )}
      </div>
    );
  }

  return (
    <div className="relative mb-4 border p-4 rounded shadow">
      <div className="absolute top-2 right-2 flex gap-2">
        <div
          className="text-gray-400 hover:text-gray-600 cursor-pointer"
          onClick={() => setModo('vista')}
        >
          <FiCheck />
        </div>
        <div
          className="text-red-500 hover:text-red-700 cursor-pointer"
          onClick={() => onDelete(index)}
        >
          <FiTrash />
        </div>
      </div>
      <div className="mb-2">
        <label className="block text-sm font-medium">Tipo</label>
        <select
          className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-500"
          value={contenido.tipo}
          onChange={(e) => onChange(index, 'tipo', e.target.value)}
        >
          <option value="texto">Texto</option>
          <option value="h1">Encabezado H1</option>
          <option value="h2">Encabezado H2</option>
          <option value="imagen">Imagen</option>
          <option value="codigo">Código</option>
          <option value="cita">Cita</option>
          <option value="lista">Lista</option>
          <option value="enlace">Enlace</option>
        </select>
      </div>
      {contenido.tipo === 'imagen' ? (
        <>
          <label className="block text-sm font-medium mb-1">Seleccionar imagen</label>
          <select
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-500"
            value={contenido.contenido || ''}
            onChange={(e) => handleImageSelect(e.target.value)}
          >
            <option value="" disabled>
              Seleccione una imagen...
            </option>
            {(imagenes || []).map((img) => (
              <option key={img.id} value={img.id}>
                {img.nombre_unico}
              </option>
            ))}
          </select>
              {contenido.contenido && (
          <img
            src={contenido.contenido}
            alt="Vista previa seleccionada"
            className="max-w-full rounded shadow mt-4"
          />
        )}
        </>
      ) : contenido.tipo === 'lista' ? (
        <textarea
          className="w-full border border-gray-300 rounded p-2 mt-2 focus:outline-none focus:ring focus:ring-blue-500"
          value={contenido.contenido}
          onChange={(e) => onChange(index, 'contenido', e.target.value)}
          placeholder="Ingrese cada ítem de la lista separado por comas..."
        />
      ) : contenido.tipo === 'enlace' ? (
        <>
          <input
            type="text"
            className="w-full border border-gray-300 rounded p-2 mt-2 focus:outline-none focus:ring focus:ring-blue-500"
            value={contenido.contenido || ''}
            onChange={(e) => handleLinkChange('nombre', e.target.value)}
            placeholder="Nombre del enlace..."
          />
          <input
            type="url"
            className="w-full border border-gray-300 rounded p-2 mt-2 focus:outline-none focus:ring focus:ring-blue-500"
            value={contenido.contenido_binario || ''}
            onChange={(e) => handleLinkChange('url', e.target.value)}
            placeholder="URL del enlace..."
          />
        </>
      ) : (
        <textarea
          className="w-full border border-gray-300 rounded p-2 mt-2 focus:outline-none focus:ring focus:ring-blue-500"
          value={contenido.contenido}
          onChange={(e) => onChange(index, 'contenido', e.target.value)}
          placeholder="Escribe aquí el contenido..."
        />
      )}
    </div>
  );
};

export default ContentRenderer;
